const requestInterval = function (fn, delay) {
  const requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      function (callback, element) {
        window.setTimeout(callback, 1000 / 60)
      }
    )
  })()
  let start = new Date().getTime()
  const handle = {}
  function loop() {
    handle.value = requestAnimFrame(loop)
    const current = new Date().getTime()
    const delta = current - start
    if (delta >= delay) {
      fn.call()
      start = new Date().getTime()
    }
  }
  handle.value = requestAnimFrame(loop)

  return handle
}

export default requestInterval
